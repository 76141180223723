import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import dashboard from 'store/dashboard/dashboardSlide'
import teams from 'store/teams/teamsSlide'
import visit from 'store/visitDrawer/visitDrawerSlice'
import price from 'store/priceDrawer/priceDrawerSlice'
import poll from 'store/pollDrawer/pollDrawerSlice'
import bayi from 'store/pollDrawer/bayiDrawerSlice'
import egitim from 'store/pollDrawer/egitimDrawerSlice'

const reducer = combineReducers({
    common,
    dashboard,
    teams,
    visit,
    price,
    poll,
    bayi,
    egitim
})

export default reducer